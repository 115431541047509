import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import './assets/custom.css'
import './assets/adobe-proxima-nova.css'
import VueGtag from "vue-gtag";
import router from './router'
// import FloatingVue from 'floating-vue'
import VueCookies from 'vue-cookies'

createApp(App).use(router).use(VueCookies).use(VueGtag, {config: {id: "G-FRLEX04GP9"}}).mount('#app')
